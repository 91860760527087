import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';

import cn from './styles.less';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

type TaskEntity = TaskEntityOfType<'OverpaymentRefundApprovalTaskProjection'>;

export const OverpaymentRefundApprovalSubRow = ({
  data
}: {
  data: TaskEntity;
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<TaskEntity> = [
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.paymentType'),
      dataIndex: 'paymentType',
      render: (_, data) =>
        t(`bo.taskManagement.paymentTypes.${data.paymentType}`)
    },
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.paymentStatus'),
      dataIndex: 'paymentStatus',
      render: (_, data) =>
        t(`bo.taskManagement.paymentStatuses.${data.paymentStatus}`)
    },
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.overpaymentAmount'),
      dataIndex: 'overpaymentAmount',
      render: (_, data) =>
        formatCurrency({
          amountMinorUnits: Math.abs(data.overpaymentAmount),
          currencyCode: data.order.totalPriceGross.currencyCode
        })
    }
  ];

  return (
    <Table
      dataSource={[data]}
      columns={columns}
      bordered
      className={cn.table}
      pagination={false}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
};
