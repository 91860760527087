import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { Table, TableColumnsType } from 'antd';
import { get, isEqual, isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { PriceDto, RefundPendingTaskProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';

interface Entity {
  cashOutReason: string;
  cancellationReasonComment: string;
  cashOutRequestDate: string;
  refundAmount: PriceDto;
  isOracle: boolean;
}

interface Props {
  data: RefundPendingTaskProjection;
}

export function RefundSubRow({ data }: Props) {
  const {
    cashOutReason,
    cancellationReasonComment,
    cashOutRequestDate,
    refundAmount,
    order
  } = data;
  const { t } = useTranslation();
  const isOracle = get(['isOracle'], order);
  const dataSource = [
    {
      cashOutReason,
      cancellationReasonComment,
      cashOutRequestDate,
      refundAmount,
      isOracle
    }
  ];

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.refundTable.carHandoverOnDate'),
      dataIndex: 'cashOutReason',
      key: 'cashOutReason',
      render: (value) => (
        <span data-qa-selector="cashOutReason">
          {isEqual(value, 'null - null') || isNil(value) ? '-' : value}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.refundTable.cancellationReasonComment'),
      dataIndex: 'cancellationReasonComment',
      key: 'cancellationReasonComment',
      render: (value) => (
        <span data-qa-selector="cancellationReasonComment">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.refundTable.cashOutRequestDate'),
      dataIndex: 'cashOutRequestDate',
      key: 'cashOutRequestDate',
      render: (value) => (
        <span data-qa-selector="cashOutRequestDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.refundTable.refundAmount'),
      dataIndex: 'refundAmount',
      key: 'refundAmount',
      render: (value) => {
        const formattedCurrency = value && formatCurrency(value);
        return (
          <span data-qa-selector="refundAmount">
            {formattedCurrency || '-'}
          </span>
        );
      }
    },
    {
      title: t('bo.taskManagement.refundTable.isOracle'),
      dataIndex: 'isOracle',
      key: 'isOracle',
      render: (value) => (
        <span data-qa-selector="isOracle">{value ? 'yes' : 'no'}</span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="cashOutReason"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
